.filter,
.sort {
  display: flex;
  align-items: center;
  span.filtName {
    margin-right: 8px;
    color: #9ca3af;
    font-weight: 400;
    font-size: 14px;
  }
  button.filterApps {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #111827;
    svg {
      margin-left: 6px;
      path {
        stroke: #111b2b;
      }
    }
  }
}

#filterPop,
#sortPop {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding: 4px;
  border: 1px solid #e5e7eb;
  span.menuHead {
    font-weight: 400;
    font-size: 13px;
    color: #9ca3af;
    padding: 8px 15px;
  }
  button {
    border: 0;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    box-shadow: none;
    color: #6b7280;
    font-weight: 400;
    font-size: 13px;
    margin-top: 1px;
    min-height: 32px;
    max-height: 32px;
    svg {
      path {
        fill: none;
      }
    }
  }
  button.api-btn {
    padding: 12px;
    cursor: pointer;
    background-color: #fff;
  }
  button:hover {
    background: #f3f4f6;
  }
  .checkedActive {
    color: #111827;
    font-weight: 500;
    background: #f3f4f6;
    svg {
      path {
        fill: #036fe3;
      }
    }
  }
}
