.sidebar {
    width: 240px;
    background: #f9fafb;
    min-height: 100vh;
    position: fixed;
    .logo {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        margin-bottom: 10px;
        background-color: #fff;
        height: 48px;
        border-bottom: 1px solid #f3f4f6;
        h1 {
            margin-left: 9px;
            color: #111827;
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.36px;
        }
    }
    .sideNav {
        padding: 12px;
        button.sideLink {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            padding: 5px 24px;
            outline: none;
            border: 0;
            border-radius: 4px;
            width: 100%;
            background: transparent;
            cursor: pointer;
            span {
                margin-left: 16px;
                font-weight: 500;
                line-height: 20px;
                color: #4b5563;
            }
        }
        button.sideLink:hover,
        button.active {
            transition: 0.2s ease-in-out;
            // svg {
            //     path {
            //         fill: #0059C8;
            //     }
            // }
            // span {
            //     color: #0059C8;
            // }
            background-color: #dde1ebc8;
        }
    }
}
