 .topbar {
    border-bottom: 1px solid #F3F4F6;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 4px 32px;
    .dropdownMenu {
        button.dropDwn {
            display: flex;
            align-items: center;
            padding: 8px;
            border-radius: 6px;
            border: 0;
            cursor: pointer;
            background: transparent;
            span.mg10 {
                margin-right: 10px;
            }
        }
        button.isActive {
            background: #E5E7EB;
        }
    }
}
#popMenu {
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 15px 4px 4px 4px;
    border: 1px solid #E5E7EB;
    .userDeets {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F3F4F6;
        padding: 0px 16px 16px 16px;
        .nameEmail {
            margin-left: 10px;
            p.name {
                font-weight: 500;
                line-height: 16px;
                color: #111827;
                margin-bottom: 3px;
                font-size: 15px;
            }
            p.email {
                color: #9CA3AF;
                font-size: 14px;
            }
        }
    }
    .btnLinks {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        button.drpdwnLink {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: 0;
            padding: 10px 16px;
            cursor: pointer;
            border-radius: 4px;
            span {
                margin-left: 12px;
                color: #6B7280;
                font-size: 14px;
            }
        }
        button.drpdwnLink:hover {
            background: #F3F4F6;
            svg {
                path {
                    stroke: #292D32;
                }
            }
            span {
                color: #111827;
            }
        }
    }
}