main {
  .padLayout {
    padding: 6px 32px 50px 32px;
    .topLayout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3.pageName {
        color: #111b2b;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
      .searchNCreate {
        span.ant-input-affix-wrapper {
          background: #f9fafb;
          border-color: #f9fafb;
          height: 32px;
        }
        span.ant-input-affix-wrapper-focused {
          border-color: #036fe3;
        }
        display: flex;
        align-items: center;
        button.createNew {
          margin-left: 16px;
          min-height: 32px;
          padding: 0px 12px;
          font-weight: 600;
          max-height: 32px;
          font-size: 12px;
          line-height: 16px;
        }
        input {
          background: #f9fafb;
          border: 0;
          width: 320px;
          box-shadow: none;
          border: 1px solid #f9fafb;
          border-radius: 6px;
        }
        input::placeholder {
          color: #d1d5db;
        }
      }
    }
    .filtersToggle {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .sortToggle {
        display: flex;
        align-items: center;
        .toggle {
          margin-left: 20px;
          .ant-segmented {
            background: #f9fafb;
            .ant-segmented-item {
              .ant-segmented-item-label {
                display: flex;
                align-items: center;
                padding: 0px 4px;
                min-height: 24px;
                span.ant-segmented-item-icon {
                  display: flex;
                }
              }
              .ant-segmented-item-label:hover {
                background-color: transparent;
              }
            }
            .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
                .ant-segmented-item-disabled
              )::after {
              background-color: #00000005;
            }
          }
        }
      }
    }
    .mainContent {
      .appsSpinBox {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .gridApps {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 0px;
        gap: 30px;
      }
      .listTable {
        padding: 32px 0px;
        .ant-table-wrapper {
          .ant-table-cell {
            span.faint {
              color: #9ca3af;
            }
            .listAppName {
              display: flex;
              align-items: center;
              cursor: pointer;
              span.appnam {
                color: #111827;
                font-weight: 600;
                margin-left: 16px;
              }
            }
            .flex-end {
              display: flex;
              button.menu {
                border: 0;
                background-color: transparent;
                cursor: pointer;
                border-radius: 6px;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              button.openActive {
                background: #e5e7eb;
              }
            }
          }
          .ant-table-tbody > tr > td {
            border: 0px;
          }
          .ant-table-thead
            > tr
            > th:not(:last-child):not(.ant-table-selection-column):not(
              .ant-table-row-expand-icon-cell
            ):not([colspan])::before {
            background-color: transparent;
          }
          .ant-table-thead > tr > th {
            background-color: transparent;
            border-bottom: 0px;
            color: #9ca3af;
            font-weight: 500;
            .viewHead {
              display: flex;
              align-items: center;
              span {
                margin-right: 6px;
              }
            }
          }
        }
      }
      .centEmp {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.createNew {
  .ant-modal-content {
    padding: 0px;
    border-radius: 6px;
    height: 80vh;
    overflow-y: scroll;
    button.ant-modal-close {
      top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-modal-header {
      border-bottom: 1px solid #f3f4f6;
      padding: 14px 24px;
      .ant-modal-title {
        color: #111827;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .ant-modal-body {
      .createNewBody {
        padding: 12px 24px;
        .formField {
          border-bottom: 1px solid #f3f4f6;
          padding-bottom: 24px;
          input {
            background: #f9fafb;
            border-color: #f9fafb;
            color: #111827;
            font-weight: 600;
          }
          input:focus {
            border-color: #036fe3;
          }
          label {
            color: #111827;
            font-weight: 500;
          }
        }
        .templatesContainer {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          padding-top: 24px;
        }
        .browseAllTemplates {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          p.template {
            color: #374151;
            font-weight: 600;
            font-size: 16px;
          }
          button.browse {
            background-color: #fff;
            border: 0px;
            cursor: pointer;
            color: #111827;
            font-weight: 500;
            padding: 8px 15px;
            border-radius: 6px;
          }
          button.browse:hover {
            background-color: #0000000f;
          }
        }
      }
    }
  }
  .ant-modal-content::-webkit-scrollbar {
    width: 0px;
  }
  .ant-modal-content::-webkit-scrollbar-thumb {
    background: #fff;
  }
  .ant-modal-content::-webkit-scrollbar-track {
    background: #fff;
  }
}
