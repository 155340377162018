.groupCard {
  .ant-collapse {
    border: 0px;
    border-radius: 0px;
    background-color: #fff;
    .ant-collapse-item {
      border-radius: 0px;
      border-bottom: 0px;
      &:hover {
        background: #f9fafb;
      }
      .callSpinner {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-collapse-header {
        padding: 7px 15px;
        color: #111827;
        font-weight: 600;
        letter-spacing: -0.154px;
        border-radius: 0px;
        button {
          background-color: transparent;
          border: 0px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          width: 24px;
          height: 24px;
        }
        button:hover,
        button.actv {
          background: #e5e7eb;
        }
      }
      .ant-collapse-content {
        border-top: 0px;
        .ant-collapse-content-box {
          padding: 5px 0px;
        }
        .empCall {
          padding-left: 40px;
          color: #9ca3af;
          font-size: 12px;
          span.add {
            color: #036fe3;
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
    }
  }
}
