.callCont {
  padding: 6px 16px;
  display: flex;
  border: 1px solid transparent;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .padBadge {
      width: 55px;
      display: flex;
    }
    p.callName {
      margin-left: 16px;
    }
  }
  .right {
    button {
      background-color: transparent;
      border: 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      width: 24px;
      height: 24px;
    }
    button:hover,
    button.actv {
      background: #e5e7eb;
    }
  }
}
.callCont:hover {
  background: #f9fafb;
}
.selectedCall {
  border: 1px solid #f3f4f6;
  background: #f9fafb;
}
.selectedCall:hover {
  border: 1px solid #f3f4f6;
}
