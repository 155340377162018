.gridContainer {
  width: 254px;
  cursor: pointer;
  img {
    height: 268px;
    border-radius: 6px;
  }
  .cardDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    .leftDeets {
      p.appName {
        font-weight: 600;
        color: #111827;
        font-size: 15px;
        word-break: break-all;
      }
      p.lastViewed {
        color: #9ca3af;
        font-weight: 500;
        font-size: 13px;
      }
    }
    .rightDeets {
      button.menu {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        border-radius: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button.openActive {
        background: #e5e7eb;
      }
    }
  }
}
.gridContainer:hover {
  img {
    border: 1px solid #e5e7eb;
  }
}

#gridPopover {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  min-width: 130px;
  padding: 5px;
  border: 1px solid #e5e7eb;
  .dropWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button.menuDrop {
      width: 100%;
      display: flex;
      align-items: center;
      border: 0;
      background-color: transparent;
      color: #6b7280;
      cursor: pointer;
      padding: 0px 10px;
      min-height: 32px;
      max-height: 32px;
    }
    button.menuDrop:hover {
      background: #f3f4f6;
      border-radius: 4px;
    }
  }
}

.renameModal,
.deleteModal {
  .ant-modal-content {
    padding: 0px;
    .ant-modal-header {
      border-bottom: 1px solid #f3f4f6;
      padding: 12px 16px 8px 16px;
      color: #111827;
    }
    .ant-modal-body {
      padding: 8px 16px 16px 16px;
      input {
        background: #f9fafb;
        border-color: #f9fafb;
        color: #111827;
        margin-bottom: 16px;
        font-weight: 500;
      }
      input:focus {
        border-color: #036fe3;
      }
      p {
        color: #6b7280;
      }
    }
    .ant-modal-footer {
      margin-top: 0px;
      .renameFooter {
        display: flex;
        justify-content: space-between;
        padding: 0px 16px 16px 16px;
        button.cancel {
          width: 48%;
          background: #f3f4f6;
          border-radius: 6px;
          padding: 12px;
          border: 0px;
          color: #111827;
          font-weight: 600;
          cursor: pointer;
        }
        button.update {
          width: 48%;
          background: #036fe3;
          border-radius: 6px;
          border: 0px;
          color: #ffffff;
          font-weight: 600;
          cursor: pointer;
          svg {
            fill: #fff;
          }
        }
        button.delete {
          width: 48%;
          background: #da294a;
          border-radius: 6px;
          border: 0px;
          color: #ffffff;
          font-weight: 600;
          cursor: pointer;
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}

.ant-message {
  bottom: 16px;
  top: unset !important;
}

.ant-message-notice .ant-message-notice-content {
  background: #4b5563;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  color: #ffffff;
}
[class^="ant-message"] [class^="ant-message"],
[class*=" ant-message"] [class^="ant-message"],
[class^="ant-message"] [class*=" ant-message"],
[class*=" ant-message"] [class*=" ant-message"] {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 8px;
  }
}
