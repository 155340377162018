span.api-badge {
  font-weight: 600;
  font-size: 10px;
  border-radius: 10px;
  padding: 4px 8px 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
span.get {
  background: #eaf9e8;
  color: #5db057;
}
span.post {
  background: #f7f2ff;
  color: #8553e7;
}
span.put {
  background: #fff2e4;
  color: #f07f23;
}
span.patch {
  background: #e8f5ff;
  color: #036fe3;
}
span.delete {
  background: #fff2f2;
  color: #da294a;
}
