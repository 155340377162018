.emptyWrapper {
  background: #f9fafb;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  border-radius: 6px;
  margin-top: 60px;
  p.no {
    color: #4b5563;
    font-weight: 600;
    margin-bottom: 8px;
  }
  p.sub {
    color: #9ca3af;
    text-align: center;
  }
  button {
    background: #036fe3;
    border: 1px solid #036fe3;
    border-radius: 6px;
    color: #fff;
    padding: 8px 12px;
    margin-top: 16px;
    cursor: pointer;
  }
  button:hover {
    background: #0059c8;
    transition: 0.2s ease-in-out;
  }
}
.noBgWrapper {
  background-color: #fff;
  .addMg {
    margin-top: 30px;
  }
  button.noBg {
    background-color: #fff;
    border-color: #fff;
    color: #036fe3;
    font-weight: 600;
    margin: 8px 0px;
  }
}
