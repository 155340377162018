.templateContainer {
  width: 250px;
  .imgWrapper {
    position: relative;
    img {
      height: 268px;
      width: 250px;
      border-radius: 6px;
    }
    .hoverDetails {
      position: absolute;
      top: 0;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 268px;
      background: linear-gradient(
        180deg,
        rgba(48, 62, 83, 0.48) 0%,
        rgba(0, 0, 0, 0) 116.79%
      );
      box-shadow: 0px 2px 4px rgba(42, 53, 69, 0.08);
      border-radius: 6px;
      color: #fff;
      p.templateTitle {
        font-weight: 600;
        margin-bottom: 5px;
      }
      p.templateDetails {
        font-size: 13px;
        font-weight: 400;
        color: #f3f4f6;
      }
      button {
        background: #036fe3;
        padding: 6px;
        border: 1px solid #036fe3;
        border-radius: 6px;
        color: #ffffff;
        margin-top: 8px;
        cursor: pointer;
        svg {
          fill: #ffffff;
        }
        &:disabled {
          cursor: not-allowed;
        }
        min-height: 32px;
        max-height: 32px;
      }
    }
  }
  .cardDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    .leftDeets {
      p.appName {
        font-weight: 600;
        color: #111827;
        font-size: 15px;
        word-break: break-all;
      }
      p.lastViewed {
        color: #9ca3af;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}
