.templateLayout {
  .topLayout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    // border-bottom: 1px solid #f3f4f6;
    h3.pageName {
      color: #111b2b;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    .searchNcreate {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: fit-content;
    }

    .createNew {
      margin-left: 16px;
      min-height: 32px;
      padding: 0px 12px;
      font-weight: 600;
      max-height: 32px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .sortBox {
    padding: 5px 32px;
  }

  .gridTemplates {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 16px 32px;
  }

  .pagination {
    text-align: center;
  }
}
.tempsSpinBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.modalTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 64px;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;

  .templateName {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.38px;
    color: #111827;
  }

  .templateCTAs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: fit-content;
    height: 32px;
  }
}

.modalContent {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  .mediaContainer {
    width: 912px;
    height: 360px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .descriptionCont {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 912px;

    .descriptionText {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.154px;
      color: #1f2937;
      width: 580px;
    }

    .featsAndFxns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      max-width: 316px;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.154px;
        color: #111827;
      }

      p {
        font-weight: 500;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        color: #036fe3;
        padding: 2px;
      }

      .feats {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .feat {
          border-radius: 4px;
          width: 316px;
          cursor: pointer;

          .featTitleBar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            gap: 8px;
            height: 32px;
            background: #f9fafb;
          }

          .open {
            background: #f3f4f6;
          }

          .featText {
            display: flex;
            align-items: center;
            padding-left: 8px;
            height: 32px;
            background: #f3f4f6;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #6b7280;
            }
          }
        }
      }
    }
  }
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.createApp {
  // .ant-modal-content {
  //   padding: 0;
  // }
  .ant-modal-content {
    padding: 0px;
    border-radius: 6px;
    height: 144px;
    overflow-y: scroll;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 8px;
      gap: 8px;
      width: 100%;
      height: 40px;
      background-color: white;
      border-bottom: 1px solid #e5e7eb;
      position: sticky;
      top: 0;
      margin-bottom: 8px;

      .titleName {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #111827;
      }
    }

    .input {
      padding: 0 16px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      .buttonBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }
}
