.padLayout-Api {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  .topLayout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 32px;
    h3.pageName {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #111b2b;
    }
    .searchNCreate {
      span.ant-input-affix-wrapper {
        background: #f9fafb;
        border-color: #f9fafb;
        height: 32px;
      }
      span.ant-input-affix-wrapper-focused {
        border-color: #036fe3;
      }
      display: flex;
      align-items: center;
      button.createNew {
        margin-left: 16px;
        min-height: 32px;
        padding: 0px 12px;
        font-weight: 600;
        max-height: 32px;
        font-size: 12px;
        line-height: 16px;
      }
      input {
        background: #f9fafb;
        border: 0;
        width: 320px;
        box-shadow: none;
        border: 1px solid #f9fafb;
        border-radius: 6px;
      }
      input::placeholder {
        color: #d1d5db;
      }
    }
  }
  .API-top {
    border-bottom: 1px solid #f3f4f6;
  }
  .collections-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  .flex-collection {
    display: flex;
    height: stretch;
    flex-grow: 1;
    .callNames {
      width: 30%;
      .callFilter {
        padding: 16px 32px;
        border-bottom: 1px solid #f3f4f6;
      }
      .api-calls {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 8px 0px;
      }
    }
    .call-actions {
      width: 70%;
      border-left: 1px solid #f3f4f6;
      .editGrp {
        display: flex;
        align-items: center;
        padding: 14px 32px;
        border-bottom: 1px solid #f3f4f6;
        p.grpName {
          color: #111827;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: -0.154px;
          margin-right: 8px;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          width: 24px;
          background: #f9fafb;
          border-radius: 6px;
          border: 1px solid #f9fafb;
          cursor: pointer;
        }
      }
      .configCont {
        padding: 16px 32px;
        p.configTitle {
          color: #111827;
          font-weight: 600;
          font-size: 16px;
        }
        p.configTxt {
          color: #6b7280;
          font-weight: 400;
          font-size: 14px;
          margin-top: 5px;
          margin-bottom: 24px;
        }
        .callDefinition {
          input {
            background: #f9fafb;
            border-radius: 6px;
            border: 1px solid #f9fafb;
            height: 40px;
          }
          input::placeholder {
            font-weight: 300;
            font-size: 13px;
            color: #9ca3af;
          }
          input:focus {
            border: 1px solid #036fe3;
          }
          .formField {
            display: flex;
            flex-direction: column;
            margin-top: 26px;
            margin-bottom: 16px;
            label {
              font-weight: 500;
              font-size: 12px;
              color: #111827;
              margin-bottom: 8px;
              span.req {
                color: #6b7280;
                font-weight: 400;
              }
            }
          }
          .respMain {
            width: auto;
            margin-top: 24px;
            .title {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.154px;
              margin-bottom: 17px;
            }
            .testContainer {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 8px;

              width: 100%;
              min-height: 260px;
              .tabContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0px;
                gap: 10px;
                width: 100%;
                height: 32px;

                button.test {
                  background: #f3f4f6;
                  color: #111827;
                  border: 0;
                }
              }
              .noResponse {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid #f3f4f6;
                border-radius: 5px;
                gap: 4px;
                width: 100%;
                min-height: 300px;

                .noResponseTitle {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;
                  letter-spacing: -0.154px;
                  color: #4b5563;
                }

                .noResponseText {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -0.154px;
                  color: #9ca3af;
                }

                button.test {
                  background: #f3f4f6;
                  color: #111827;
                  border: 0;
                }
              }
              .testHeader {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0px;
                gap: 8px;
                width: 100%;
                height: 32px;

                .status {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  gap: 8px;
                  width: 131px;
                  height: 18px;

                  .statusText {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    color: #9ca3af;
                  }
                  > .greenText {
                    color: green;
                  }
                  > .redText {
                    color: red;
                  }
                }
              }
              .headerSnippetCont {
                border: 1px solid #f3f4f6;
                border-radius: 5px;
                width: 100%;
                height: fit-content;
              }
              .testResponse {
                width: 100%;
                border-radius: 6px;
                margin: 0px 0px 24px 0px;
                // .ant-tabs-nav-wrap {
                //   padding-left: 16px;
                //   .ant-tabs-tab {
                //     color: #9ca3af;
                //   }
                //   .ant-tabs-tab-btn:hover {
                //     color: #0059c8;
                //   }
                //   .ant-tabs-ink-bar {
                //     background-color: #0059c8;
                //   }
                // }
                .ant-tabs-nav {
                  padding-top: 0;
                  height: 32px;
                }
                .ant-tabs-extra-content {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px;
                  gap: 8px;
                  width: 80%;
                  height: 32px;

                  .status {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    width: 131px;
                    height: 18px;

                    .statusText {
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 1.6px;
                      text-transform: uppercase;
                      color: #9ca3af;
                    }
                    > .greenText {
                      color: green;
                    }
                    > .redText {
                      color: red;
                    }
                  }
                }
                .ant-tabs-content-holder {
                  border: 1px solid #f3f4f6;
                  border-radius: 5px;
                }
                .headerBar {
                  .editorBox {
                    border: 1px solid #f3f4f6;
                  }
                }
              }
            }
          }
          .flexForm {
            display: flex;
            justify-content: space-between;
            .method {
              width: 25%;
              margin-right: 16px;
              .ant-select {
                height: 40px;
                width: 100% !important;
                .ant-select-selector {
                  height: 40px;
                  background: #f9fafb;
                  border-radius: 6px;
                  border: 1px solid #f9fafb;
                  .ant-select-selection-item {
                    line-height: 40px;
                  }
                }
                .ant-select-selector:focus,
                .ant-select-selector:hover {
                  border: 1px solid #036fe3;
                }
              }
            }
            .reqURL {
              width: 75%;
            }
          }
          .tabs {
            border: 1px solid #f3f4f6;
            border-radius: 6px;
            margin: 8px 0px 24px 0px;
            .ant-tabs-nav-wrap {
              padding-left: 16px;
              .ant-tabs-tab {
                color: #9ca3af;
              }
              .ant-tabs-tab-btn:hover {
                color: #0059c8;
              }
              .ant-tabs-ink-bar {
                background-color: #0059c8;
              }
            }
            .ant-tabs-content-holder {
              padding: 0px 16px 16px 16px;
            }
            .headers {
              .flex-header {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .header-input {
                  width: 100%;
                  margin-right: 8px;
                }
                .ant-select {
                  height: 40px;
                  .ant-select-selector {
                    height: 40px;
                    background: #f9fafb;
                    border-radius: 6px;
                    border: 1px solid #f9fafb;
                    .ant-select-selection-item {
                      line-height: 40px;
                    }
                  }
                }
                button.closeIcon {
                  width: 40px;
                  height: 40px;
                  background: #f9fafb;
                  border-radius: 6px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 0px;
                  cursor: pointer;
                }
                button.padded {
                  margin-top: 22px;
                }
                .flex-qParams {
                  display: flex;
                  gap: 8px;
                  margin-bottom: 16px;
                  width: 100%;
                  label {
                    color: #111827;
                  }
                  .queryName,
                  .valueSource,
                  .type,
                  .value {
                    flex-grow: 1;
                  }
                }
              }
              button.addHead {
                display: flex;
                align-items: center;
                background: #f3f4f6;
                border-radius: 6px;
                padding: 8px 12px;
                border: 0px;
                color: #111827;
                cursor: pointer;
                span {
                  margin-left: 5px;
                }
                svg {
                  fill: #111827;
                }
              }
              button.addHead:hover {
                background: #e5e7eb;
                transition: 0.2s;
              }
              .bodyColumn {
                flex-direction: column;
                align-items: flex-start;
                label {
                  font-size: 13px;
                  margin-bottom: 4px;
                }
              }
            }
            .advSettings {
              color: #4b5563;
              p.light {
                color: #9ca3af;
                font-weight: 400;
                font-size: 12px;
              }
              p.redWdth {
                width: 70%;
              }
              .flexSwitch {
                margin: 10px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p.headPrx {
                  font-size: 13px;
                }
              }
              .customProxySettings {
                padding: 0px 20px;
                border-bottom: 1px solid #f3f4f6;
                .advFlex {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  gap: 26px;
                  margin: 16px 0px;
                  .rhs {
                    width: 80%;
                    textarea {
                      background: #f9fafb;
                      border-radius: 6px;
                      border-color: #f9fafb;
                      &::placeholder {
                        font-size: 12px;
                      }
                      &:focus {
                        border: 1px solid #036fe3;
                      }
                    }
                  }
                  .PASSW {
                    span {
                      padding: 0px 5px 0px 10px;
                      background: #f9fafb;
                      border-color: #f9fafb;
                      input {
                        border: 0px;
                        &:focus {
                          border: 0px;
                          outline: 0;
                        }
                      }
                    }
                  }
                  .pxServerInput {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .proxy {
                      width: 200%;
                    }
                  }
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
          .actionButtons {
            display: flex;
            justify-content: space-between;
            button {
              padding: 8px 12px;
              border-radius: 6px;
              border: 0px;
              cursor: pointer;
              svg {
                fill: #fff;
              }
            }
            .leftBtns {
              button.delete {
                background: #da294a;
                color: #ffffff;
              }
              button.delete:hover {
                background: #7f0010;
                transition: 0.2s;
              }
            }
            .rightBtns {
              display: flex;
              gap: 8px;
              button.cancel {
                background: #f3f4f6;
                color: #111827;
              }
              button.blue {
                background: #036fe3;
                color: #ffffff;
              }
              button.blue:hover {
                background: #0059c8;
                transition: 0.2s;
              }
            }
          }
        }
      }
      .noSelections {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 24px;
        width: 640px;
        height: 480px;
        background: #f9fafb;
        border-radius: 6px;
        margin: 10% auto;

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 200px;
          height: 64px;

          .header {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.154px;
            color: #4b5563;
          }

          .subText {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.154px;
            color: #9ca3af;
          }
        }
      }
      .emptycont {
        display: flex;
        justify-content: center;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-ixblex).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #f3f4f6;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #036fe3 !important;
}
:where(.css-dev-only-do-not-override-ixblex).ant-form-item {
  margin-bottom: 0px;
}
:where(.css-dev-only-do-not-override-ixblex).ant-switch.ant-switch-checked,
:where(.css-dev-only-do-not-override-ixblex).ant-checkbox-checked .ant-checkbox-inner {
  background: #036fe3;
}
